import {
  BOOKS_APPSTORE_LINK,
  BOOKS_PLAYSTORE_LINK,
  TELIO_APPSTORE_LINK,
  TELIO_PLAYSTORE_LINK,
  ZALO_LINK,
  FACEBOOK_SHARE_URL,
  EMAIL_SHARE_URL,
  LINKEDIN_SHARE_URL,
} from '../constants';

export const openInNewTab = (url: string) => {
  window.open(url, '_blank');
};

export const openTelioZalo = () => {
  openInNewTab(ZALO_LINK);
};

export const openTelioBooksAppStore = () => {
  openInNewTab(BOOKS_APPSTORE_LINK);
};

export const openTelioBooksPlayStore = () => {
  openInNewTab(BOOKS_PLAYSTORE_LINK);
};

export const openTelioAppAppStore = () => {
  openInNewTab(TELIO_APPSTORE_LINK);
};

export const openTelioAppPlayStore = () => {
  openInNewTab(TELIO_PLAYSTORE_LINK);
};

export const shareFacebook = (contentURL: string) => {
  openInNewTab(FACEBOOK_SHARE_URL + contentURL);
};

export const shareEmail = (contentURL: string) => {
  openInNewTab(EMAIL_SHARE_URL + contentURL);
};

export const shareLinkedIn = (contentURL: string) => {
  openInNewTab(LINKEDIN_SHARE_URL + contentURL);
};

export const createMetaDescription = (content: string) => {
  return content
    .replace(/<[^>]*>?/gm, '')
    .replace(/&nbsp;/gm, '')
    .replace(/\n/gm, '')
    .slice(0, 160);
};
export const setCookie = (locale: string) => {
  document.cookie = `NEXT_LOCALE=${locale}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
};
