/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import { WrapImage } from '../wrapImage';
import styles from './styles.module.css';
import { useRouter } from 'next/router';
import { stores, TELIO_BLOG, TELIO_WEBSITE } from '../../constants';
import {
  openTelioAppAppStore,
  openTelioAppPlayStore,
  // openTelioAppAppStore,
  // openTelioAppPlayStore,
  openTelioZalo,
} from '../../utils';
import Dialog from '@mui/material/Dialog';
import { T } from '../../hooks/translation';

type MobileMenuProps = {
  showDrawer: boolean;
  toggleDrawer: any;
  setShowDrawer: any;
};
function MobileMenu({
  showDrawer,
  toggleDrawer,
  setShowDrawer,
}: MobileMenuProps) {
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;

  const navigate = useCallback(
    (href: string) => {
      setShowDrawer(false);
      router.push(href);
    },
    [router, setShowDrawer]
  );

  const handleLanguageChange = useCallback(
    (value: string) => {
      if (locale !== value) {
        router.push({ pathname, query }, asPath, { locale: value });
      }
      setShowDrawer(false);
    },
    [asPath, locale, pathname, query, router, setShowDrawer]
  );

  return (
    <>
      <Dialog onClose={toggleDrawer(false)} open={showDrawer} fullScreen={true}>
        <div>
          <div className={styles.rowMobileMenu}>
            <div className={styles.wrapClose} onClick={toggleDrawer(false)}>
              <div>{T('dong')}</div>
              <WrapImage
                desktop={{
                  src: '/images/Exit.png',
                  alt: 'Exit',
                  width: 24,
                  height: 24,
                  style: { display: 'flex', marginLeft: '4px' },
                }}
              />
            </div>
          </div>
          <div className={styles.wrapMenuContent}>
            <div
              className={`${styles.rowMobileMenu} ${styles.menuTitle}`}
              onClick={() => navigate('/')}
            >
              {T('tuyen-dung')}
            </div>
            <a
              href={TELIO_BLOG + T('link')}
              className={`${styles.rowMobileMenu} ${styles.menuTitle}`}
            >
              blog
            </a>
            <a
              href={TELIO_WEBSITE + T('link') + '/hoadon'}
              className={`${styles.rowMobileMenu} ${styles.menuTitle}`}
            >
              {T('download-vat')}
            </a>

            <div className={`${styles.menuTitle}`}>{T('ngon-ngu')}</div>
            <div className={`${styles.rowMobileMenu} ${styles.itemList}`}>
              <div
                className={styles.wrapMemuItem}
                onClick={() => handleLanguageChange('vi')}
              >
                <WrapImage
                  desktop={{
                    src: '/images/vi.png',
                    alt: 'VN',
                    width: 24,
                    height: 24,
                    style: { display: 'flex', marginRight: '12px' },
                  }}
                />
                {T('language.vietnamese')}
              </div>
              <div
                className={styles.wrapMemuItem}
                onClick={() => handleLanguageChange('en')}
              >
                <WrapImage
                  desktop={{
                    src: '/images/en.png',
                    alt: 'USA',
                    width: 24,
                    height: 24,
                    style: { display: 'flex', marginRight: '12px' },
                  }}
                />
                {T('language.english')}
              </div>
            </div>
            <div className={`${styles.menuTitle}`}>{T('san-pham')}</div>
            <div className={`${styles.rowMobileMenu} ${styles.itemList}`}>
              <div
                className={`${styles.wrapMemuItemApp} ${styles.mobileApp}`}
                onClick={openTelioAppPlayStore}
              >
                <WrapImage
                  desktop={{
                    src: '/images/playStoreIcon.svg',
                    alt: 'USA',
                    width: 24,
                    height: 24,
                    style: { display: 'flex', marginRight: '12px' },
                  }}
                />
                {T('ung-dung-telio')} (Android)
              </div>
              <div
                className={`${styles.wrapMemuItemApp} ${styles.mobileApp}`}
                onClick={openTelioAppAppStore}
              >
                <WrapImage
                  desktop={{
                    src: '/images/appStoreIcon.svg',
                    alt: 'Download',
                    width: 24,
                    height: 24,
                    style: { display: 'flex', marginRight: '12px' },
                  }}
                />
                {T('ung-dung-telio')} (iOS)
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default MobileMenu;
