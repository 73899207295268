import React, { CSSProperties, ReactElement } from 'react';
import styles from './styles.module.css';
type ClickButtonProps = {
  className?: string;
  style?: CSSProperties;
  children?: ReactElement;
  onClick?: any;
};

export const ClickButton = (props: ClickButtonProps) => {
  const { className, style, children, onClick } = props;
  return (
    <button
      className={className + ' ' + styles.button}
      style={style}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
