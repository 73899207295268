import * as React from 'react';
import { WrapImage } from '../wrapImage';
import styles from './styles.module.css';
import { T } from '../../hooks/translation';

const { useEffect, useCallback, useState } = React;

const DropDownToolTip = (props: any) => {
  const {
    options,
    onClick,
    onClickOutside,
    id,
    customContainerStyle,
    customClassName,
  } = props;
  const [didMount, setDidMount] = useState(false);

  const elementId = `dropDownToolTip-${id}`;

  const detectOutsideClick = useCallback(
    (event) => {
      const dropDown = document.getElementById(elementId);
      if (dropDown && !dropDown.contains(event.target)) {
        onClickOutside();
      }
    },
    [elementId, onClickOutside]
  );

  useEffect(() => {
    if (!didMount) {
      setTimeout(() => {
        setDidMount(true);
      }, 0);
      return;
    }
    window.addEventListener('click', detectOutsideClick);
    return () => {
      window.removeEventListener('click', detectOutsideClick);
    };
  }, [detectOutsideClick, didMount]);

  return (
    <div
      style={customContainerStyle}
      className={`${styles.dropDownWrappper} ${customClassName}`}
      id={elementId}
    >
      {options.map((option: any) => (
        <div
          key={option.value}
          className={styles.dropDownOption}
          onClick={() => onClick({ target: { value: option.value } })}
        >
          <WrapImage
            desktop={{
              src: option.icon,
              alt: 'icon',
              width: 28,
              height: 28,
              className: `${styles.dropDownIcon} `,
            }}
          />
          <div className={styles.dropDownLabel}>{T(option.label)}</div>
        </div>
      ))}
    </div>
  );
};

export default DropDownToolTip;
