import React, { CSSProperties, ReactElement } from 'react';
import styles from './styles.module.css';
type SearchButtonProps = {
  className?: string;
  style?: CSSProperties;
  children?: ReactElement;
};

export const SearchButton = (props: SearchButtonProps) => {
  const { className, style, children } = props;
  return (
    <button className={className + ' ' + styles.button} style={style}>
      {children}
    </button>
  );
};
