import React, { useState } from 'react';
import { WrapImage } from '../wrapImage';

import styles from './styles.module.css';
import MobileMenu from '../mobileMenu';
import { T } from '../../hooks/translation';

const FixedHeaderMobile = () => {
  const [showDrawer, setShowDrawer] = useState(false);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setShowDrawer(open);
    };

  return (
    <div
      className={`${styles.fixedHeaderMobileContainer} fixed-header-mobile-container`}
    >
      <MobileMenu
        showDrawer={showDrawer}
        toggleDrawer={toggleDrawer}
        setShowDrawer={setShowDrawer}
      />

      <a href={`https://telio.vn/${T('link')}`}>
        <WrapImage
          desktop={{
            src: '/images/Logo-Telio.png',
            alt: 'Telio-logo',
            width: 50,
            height: 26,
            className: styles.headerZaloImage,
          }}
        />
      </a>
      <div
        className={styles.hiringContainer}
        onClick={() => setShowDrawer(true)}
      >
        <div>
          <span className={styles.sourceNowText}>{T('tuyen-dung')}</span>
        </div>
        <WrapImage
          desktop={{
            src: '/images/Down.png',
            alt: 'Down',
            width: 24,
            height: 24,
            style: { display: 'flex' },
          }}
        />
      </div>
    </div>
  );
};

export default FixedHeaderMobile;
