import React, { useState, useCallback, useMemo } from 'react';
import { WrapImage } from '../wrapImage';
import styles from './styles.module.css';
import {
  openTelioAppAppStore,
  openTelioAppPlayStore,
  setCookie,
} from '../../utils';
import DropDownToolTip from '../dropDownToolTip';
import { languages } from '../../constants';
import CustomeLink from '../CustomLink';
import { useRouter } from 'next/router';
import { T } from '../../hooks/translation';

function Header() {
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;

  const [showChangeLang, setShowChangeLang] = useState(false);

  const handleLanguageChange = useCallback(
    (event) => {
      setShowChangeLang(false);
      const { value } = event.target;
      if (locale !== value) {
        setCookie(value);
        router.push({ pathname, query: query }, asPath, {
          locale: value,
        });
      }
    },
    [asPath, locale, pathname, query, router]
  );
  const handleDropDownMenu = useCallback(() => {
    setShowChangeLang(!showChangeLang);
  }, [showChangeLang]);

  const isVietnamese = useMemo(() => locale === 'vi', [locale]);

  return (
    <div className={`${styles.fixedHeaderWrapper} fixed-header-wrapper`}>
      <div className={`${styles.fixedHeaderContainer} fixed-header-container`}>
        <div className={styles.headerTelioAppsContainer}>
          <div
            className={styles.dropDownBarWrapper}
            id='drop-down-fixed-header'
            onClick={handleDropDownMenu}
          >
            <WrapImage
              desktop={{
                src: `/images/${locale}.png`,
                alt: 'flag',
                width: 36,
                height: 36,
                className: `${styles.localeFlag} ${styles.dropdownButton}`,
              }}
            />
            <div className={styles.fixedHeaderDropDown}>
              {showChangeLang && (
                <DropDownToolTip
                  options={languages}
                  onClick={handleLanguageChange}
                  onClickOutside={handleDropDownMenu}
                  id='fixedHeaderLanguage'
                  customContainerStyle={{ minWidth: 145 }}
                />
              )}
            </div>
          </div>
          <div className={styles.downloadOptionsBox}>
            {isVietnamese ? (
              <div onClick={openTelioAppAppStore}>
                <WrapImage
                  desktop={{
                    src: '/images/app-store-vi.png',
                    alt: '',
                    layout: 'fill',
                    objectFit: 'contain',
                    className: styles.downloadOption,
                  }}
                />
              </div>
            ) : (
              <div onClick={openTelioAppAppStore}>
                <WrapImage
                  desktop={{
                    src: '/images/app-store-en.png',
                    alt: '',
                    layout: 'fill',
                    objectFit: 'contain',
                    className: styles.downloadOption,
                  }}
                />
              </div>
            )}
            {isVietnamese ? (
              <div onClick={openTelioAppPlayStore}>
                <WrapImage
                  desktop={{
                    src: '/images/play-store-vi.png',
                    alt: '',
                    layout: 'fill',
                    objectFit: 'contain',
                    className: styles.downloadOption,
                  }}
                />
              </div>
            ) : (
              <div onClick={openTelioAppPlayStore}>
                <WrapImage
                  desktop={{
                    src: '/images/play-store-en.png',
                    alt: '',
                    layout: 'fill',
                    objectFit: 'contain',
                    className: styles.downloadOption,
                  }}
                />
              </div>
            )}
          </div>
        </div>
        <div className={`${styles.headerAboutTelio} fixed-header-about-telio`}>
          <a href={`https://telio.vn/${T('link')}/hoadon`}>
            <div style={{ position: 'relative' }}>
              <span
                className={`${styles.headerAboutTelioText} ${styles.headerAdvantage} `}
              >
                {T('download-vat')}
              </span>
            </div>
          </a>
          <a href={`https://blog.telio.vn/${T('link')}`}>
            <div style={{ position: 'relative' }}>
              <span
                className={`${styles.headerAboutTelioText} ${styles.headerAdvantage} `}
              >
                blog
              </span>
            </div>
          </a>
          <CustomeLink href={`/`}>
            <div style={{ position: 'relative' }}>
              <a
                className={`${styles.headerAboutTelioText} ${styles.headerAdvantage} ${styles.choose}`}
              >
                {T('tuyen-dung')}
              </a>
              <div className={styles.headerAboutTelioChoose}></div>
            </div>
          </CustomeLink>
          <a href={`https://telio.vn/${T('link')}`}>
            <WrapImage
              desktop={{
                src: '/images/Logo-Telio.png',
                alt: 'Telio-logo',
                width: 85,
                height: 43,
                className: styles.headerZaloImage,
              }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
